import React from 'react';
import Wallets from "./Wallets";
import './WalletSelection.css';
import WalletLoading from './WalletLoading';

export default function WalletSelection({ loading, loadingMessage, status, wallets, setSelectedWallet }) {
  return (
    <>
      <div className="wallet-selection">
        {loading && (
          <div className="overlap-grid loading">
            <WalletLoading status={status} message={loadingMessage} />
          </div>
        )}
        {(wallets && Object.keys(wallets).length > 0) ? (
          <Wallets loading={loading} wallets={wallets} setSelectedWallet={setSelectedWallet} />
        ) : (
          <div className="wallet-message">
            No wallets found, please install a wallet!
          </div>
        )}
      </div>
    </>
  )
}
