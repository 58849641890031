import { useState } from "react";
import './Wallet.css';

export default function Wallet({ loading, icon, description, wallet, setSelectedWallet }) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <li
      className={`wallet-item ${loading ? "loading" : ""}`}
      onMouseOver={() => !loading && setIsHovering(true)}
      onMouseOut={() => !loading && setIsHovering(false)}
      onClick={() => !loading && setSelectedWallet(wallet)}
    >
      <div className={`wallet-icon ${isHovering ? "icon-hover" : ""}`}>
        <img src={icon} alt={description} className="icon-image" />
      </div>
      <p className={`wallet-description ${isHovering ? "text-bold" : "text-light"}`}>{description}</p>
      <p className="arrow-hidden">{`>`}</p>
    </li>
  );
}