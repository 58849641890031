import React, { useEffect, useState } from 'react';
import './WalletConnect.css'; 

import WalletSelection from './WalletSelection';
import { Lucid } from "lucid-cardano"
import { sleep } from '../lib/base';

const lucid = await Lucid.new();
const validPolicies = ["bf5da7b623cfb818267cfb86f19f9d7a229cb7e75e6834d6d5e01611","ba2ad6a186888b8a3540059b2df090033cce64505918b980ac6364db", "bd1c1fcca114afeeaf25cff38f769457d8b930a9b830ee276cc99dd0"]
const shopURl = "https://shop.valkry.life/"

export default function WalletConnect({ showOverlay, setShowOverlay }) {
  const [wallets, setWallets] = useState({})

  const [selectedWallet, setSelectedWallet] = useState(null)

  const [status, setStatus] = useState("loading")
  const [message, setMessage] = useState("loading")
  const [loading, setLoading] = useState(false)

  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const getWallets = async () => {
      let foundWallets = {};
      const cardano = window.cardano;
      if (!cardano) {
        console.log('window.cardano is not available yet');
        return;
      }

      Object.keys(cardano).forEach(obj => {
        const curObj = cardano[obj];
        if (curObj.apiVersion && curObj.icon) {
          foundWallets[(curObj.name).split(' ')[0]] = curObj;
        }
      });

      setWallets(foundWallets);
    };

    const intervalId = setInterval(() => {
      getWallets();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    (
      async () => {
        if (selectedWallet != null) {
          setLoading(true)
          setStatus("loading")
          setMessage("loading")
          await sleep(2000)

          const api = await selectedWallet.enable().catch(async (err) => {
            console.log(err)
            setStatus("error")

            if (err.info) setMessage(err.info, "error")
            else if (err.message) setMessage(err.message, "error")
            else setMessage(JSON.stringify(err), "error")

            return null
          })

          if (!api) {
            console.log('api null')
            setStatus("error")

            await sleep(3000)
            setLoading(false)

            setSelectedWallet(null)

            return
          }

          console.log('here')

          lucid.selectWallet(api)

          const utxos = await lucid.wallet.getUtxos()

          const { ada, tokens } = getTotalAda(utxos)

          const hasValidPolicyToken = Object.keys(tokens).some(policy => validPolicies.includes(policy));
          if (hasValidPolicyToken) {
            setStatus("success")
            setMessage("You are in! \n\n Flying you to our shop!")
            await sleep(4000)
            window.location.href = shopURl;
          } else {
            setStatus("error");
            setMessage("No valid policy token found.");
          }
          await sleep(2000)
          setLoading(false)
        } else {
        }
      }
    )()
  }, [selectedWallet])

  const getTotalAda = (utxos) => {
    let totalLovelace = 0
    let tokens = {}

    for (const utxo in utxos) {
      let assets = utxos[utxo].assets

      Object.keys(assets).map(asset => {
        const count = Number(assets[asset])
        if (asset == 'lovelace') totalLovelace += count
        else {
          const policy = asset.substring(0, 56)
          const assetName = asset.substring(56)

          tokens = addToPolicy(tokens, policy, assetName, count)
        }
      })
    }

    return {
      ada: (totalLovelace / 1000000).toFixed(2),
      tokens: tokens
    }
  }

  const addToPolicy = (policies, policyName, token, count) => {
    if (!policies[policyName]) {
      policies[policyName] = {};
      policies[policyName][token] = count;
    } else {
      if (!policies[policyName][token]) {
        policies[policyName][token] = count;
      } else {
        policies[policyName][token] += count;
      }
    }

    return policies;
  }

  return (
    <div className={`modal-overlay ${showOverlay ? 'visible' : ''}`}
      onClick={() => setShowOverlay(false)}>
        <div className="modal-box" onClick={handleModalClick}>
          <WalletSelection status={status} loading={loading} loadingMessage={message} wallets={wallets} setSelectedWallet={setSelectedWallet}/>
        </div>
    </div>
  );
}
