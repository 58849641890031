import { useState } from "react";
import "./App.css";
import WalletConnect from "./components/WalletConnect";


function App() {
  const [showOverlay, setShowOverlay] = useState(false); 

  return (
    <div className="App">
      <header className="App-header">
        <img src="./ravenLogo.png" className="App-logo" alt="logo" />
        <button className="btn" onClick={() => {setShowOverlay(!showOverlay); console.log(!showOverlay)}}>CONNECT</button>
        <p>&copy; 2023. Valkry, LLC. All Rights Reserved.</p>
        <WalletConnect showOverlay={showOverlay} setShowOverlay={setShowOverlay}/>
      </header>
    </div>
  );
}

export default App;
